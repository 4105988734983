<template>
  <svg-icon :data="sData" outlined v-bind="$attrs" class="pkg-feather" />
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class IconCreditCard extends Vue {
  sData: string = require("@icon/feather/credit-card.svg");
}
</script>
